import { doc, getDoc, setDoc, collection, where, query, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { RegistrationType, User } from "../../functions/src/types";

export interface UserCreditResponse {
  credit: number;
  tier: {
    id: string;
    label: string;
  };
}

export async function registerUserInFirestore(
  uid: string,
  email: string,
  type: RegistrationType,
  username: string,
) {
  const rawUser: User["raw_user"] = {
    uid: uid,
    email: email,
  };

  const userData = {
    email: email,
    registration_type: type,
    raw_user: rawUser,
    user_credit: 0,
    username: username,
    profile_picture: "gs://inkcred-dev.appspot.com/hosting/user.png",
    emailVerified: true,
    tier: "trial",
  };

  if (type === RegistrationType.Email) {
    userData.emailVerified = false;
  }

  await setDoc(doc(db, "users", uid), userData);
}

export const getUserById = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, "users", userId));
    if (userDoc.exists()) {
      return userDoc.data() as User;
    } else {
      console.error("User not found");
      return null;
    }
  } catch (error) {
    console.error("Error getting user:", error);
    return null;
  }
};

export async function generateUniqueUsername(desiredUsername?: string): Promise<string> {
  const generateRandomUsername = () => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const usernameLength = 10;
    let username = "";
    for (let i = 0; i < usernameLength; i++) {
      username += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return username;
  };

  if (desiredUsername) {
    const q = query(collection(db, "users"), where("username", "==", desiredUsername));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      return desiredUsername;
    } else {
      throw new Error("The provided username is already taken");
    }
  } else {
    while (true) {
      const username = generateRandomUsername();
      const q = query(collection(db, "users"), where("username", "==", username));
      const snapshot = await getDocs(q);
      if (snapshot.empty) {
        return username;
      }
    }
  }
}
