import React from "react";
import { Box, Typography, Unstable_Grid2 as Grid } from "@mui/material";

const Layout = ({ children }) => {
  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flex: "1 1 auto",
      }}
    >
      <Grid
        container
        sx={{
          flex: "1 1 auto",
          height: "100vh",
          padding: "none",
          margin: "none",
        }}
      >
        <Grid
          xs={12}
          lg={6}
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            justifyContent: "center",
          }}
        >
          {children}
        </Grid>
        <Grid
          xs={12}
          lg={6}
          sx={{
            alignItems: "center",
            background: "radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)",
            color: "white",
            display: "flex",
            justifyContent: "center",
            "& img": {
              maxWidth: "100%",
            },
          }}
        >
          <Box sx={{ p: 3, maxWidth: 550 }}>
            <Typography
              align="center"
              color="inherit"
              sx={{
                fontSize: "24px",
                lineHeight: "32px",
                mb: 1,
              }}
              variant="h1"
            >
              Welcome to{" "}
              <Box component="a" sx={{ color: "#15B79E" }} target="_blank">
                Inkcredible
              </Box>
            </Typography>
            <Typography align="center" sx={{ mb: 3 }} variant="subtitle1">
              Join the Inkcredible community today! Register to unlock a world of tattoo
              inspiration. Start your tattoo journey with us.
            </Typography>
            <Box
              sx={{
                p: 3,
                borderRadius: "15px",
                maxWidth: 550,
                boxShadow: "0 0px 5px 0 rgba(0,0,0,0.5)",
                transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 12px 24px 0 rgba(0,0,0,0.3)",
                },
                "& img": {
                  width: 500,
                  height: 350,
                  transition: "transform 0.3s ease-in-out",
                  "@media (max-width:600px)": {
                    height: 200,
                  },
                },
              }}
            >
              <img
                alt=""
                src="/images/login_image.png"
                style={{
                  transition: "transform 0.3s ease-in-out",
                  borderRadius: "15px",
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Layout;
