import React from "react";
import NavLayout from "../layouts/nav/layout";
import Gallery from "../components/gallery/gallery";

const GalleryPage = () => {
  return (
    <>
      <NavLayout>
        <Gallery />
      </NavLayout>
    </>
  );
};

export default GalleryPage;
