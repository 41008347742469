import React from "react";
import { Box, Container, Typography } from "@mui/material";
import NavLayout from "../layouts/nav/layout";
import HorizontalNonLinearStepper from "../components/generation/generation-steps";

const PortraitPage = () => {
  return (
    <>
      <NavLayout>
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
          <Container maxWidth="lg">
            <Typography variant="h4">Portrait</Typography>
            <HorizontalNonLinearStepper />
          </Container>
        </Box>
      </NavLayout>
    </>
  );
};

export default PortraitPage;
