import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import TopNav from "../../components/navbars/top-nav";
import SideNav from "../../components/navbars/side-nav";

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  height: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  height: "100%",
});

const NavLayout = ({ children }) => {
  const [openNav, setOpenNav] = useState(false);

  const handleNavOpen = () => {
    setOpenNav(true);
  };

  const handleNavClose = () => {
    setOpenNav(false);
  };

  return (
    <>
      <TopNav onNavOpen={handleNavOpen} />
      <SideNav open={openNav} onClose={handleNavClose} />
      <LayoutRoot>
        <LayoutContainer>{children}</LayoutContainer>
      </LayoutRoot>
    </>
  );
};

export default NavLayout;
