import React, { useState, useEffect } from "react";
import {
  Container,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { filterImagesByDate } from "./filter";
import { ImageItem, fetchAndFormatImages } from "./getImages";
import ImageModal from "../img-modal/img-modal";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useAuth } from "../../hooks/use-auth";
import { doc, updateDoc } from "@firebase/firestore";
import { db } from "../../firebase";

const Gallery = () => {
  const [itemData, setItemData] = useState<ImageItem[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<ImageItem[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState<number | null>(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const auth = useAuth();

  const userId = auth.user?.uid;

  useEffect(() => {
    if (userId) {
      fetchAndFormatImages(userId).then((formattedData) => {
        setItemData(formattedData);
        setFilteredItems(formattedData);
      });
    }
  }, [userId]);

  useEffect(() => {
    setFilteredItems(filterImagesByDate(filterValue, itemData));
  }, [filterValue, itemData]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const filtered = itemData.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase()),
    );
    setFilteredItems(filtered);
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setActiveImageIndex(index);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setActiveImageIndex(null);
  };

  const handleLikeToggle = (index: number) => {
    setConfirmationOpen(true);
    setActiveImageIndex(index);
  };

  const handleConfirmUnlike = async () => {
    if (activeImageIndex !== null) {
      const imageToRemove = filteredItems[activeImageIndex];

      try {
        const imageRef = doc(
          db,
          "tattoos",
          imageToRemove.prompt_id,
          "tattoo_imgs",
          imageToRemove.img_id,
        );
        await updateDoc(imageRef, { is_liked: false });
        const updatedItems = [...filteredItems];
        updatedItems[activeImageIndex] = { ...imageToRemove, is_liked: false };
        setFilteredItems(updatedItems);
        setConfirmationOpen(false);

        if (userId) {
          fetchAndFormatImages(userId).then((formattedData) => {
            setItemData(formattedData);
            setFilteredItems(formattedData);
          });
        }
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };

  const handleCancelUnlike = () => {
    setConfirmationOpen(false);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        My Gallery
      </Typography>
      <Paper elevation={3} sx={{ p: 1, mb: 2 }}>
        {isMobile ? (
          <div>
            <FormControl
              size="medium"
              sx={{
                minWidth: 200,
                marginBottom: 1,
              }}
            >
              <InputLabel htmlFor="filter-select">Filter by Date:</InputLabel>
              <Select
                label="Filter by Date:"
                id="filter-select"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value as string)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="this_week">This Week</MenuItem>
                <MenuItem value="this_month">This Month</MenuItem>
              </Select>
            </FormControl>
            <TextField
              size="small"
              label="Search images"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              sx={{ width: "100%" }}
            />
          </div>
        ) : (
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={8}>
              <FormControl
                size="medium"
                sx={{
                  minWidth: 200,
                }}
              >
                <InputLabel htmlFor="filter-select">Filter by Date:</InputLabel>
                <Select
                  label="Filter by Date:"
                  id="filter-select"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value as string)}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="this_week">This Week</MenuItem>
                  <MenuItem value="this_month">This Month</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                label="Search images"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                sx={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
      <Paper elevation={3} sx={{ p: 1 }}>
        <ImageList cols={isMobile ? 1 : 3} gap={8}>
          {filteredItems.map((item, index) => (
            <ImageListItem key={item.img} sx={{ borderRadius: "8px", cursor: "pointer" }}>
              <img
                srcSet={`${item.img}&w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}&w=248&fit=crop&auto=format`}
                alt={item.title}
                style={{ borderRadius: "8px" }}
                onClick={() => handleOpenModal(index)}
              />
              <ImageModal
                imgUrl={item.img}
                open={isOpen && activeImageIndex === index}
                handleClose={handleCloseModal}
                refined_prompt={item.title}
              />
              <ImageListItemBar
                title={item.title}
                sx={{ borderRadius: "8px" }}
                actionIcon={
                  <div
                    style={{
                      borderRadius: "50%",
                      paddingTop: "8px",
                      paddingRight: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleLikeToggle(index)}
                  >
                    {item.is_liked ? (
                      <FavoriteIcon style={{ color: "red" }} />
                    ) : (
                      <FavoriteBorderIcon style={{ color: "white" }} />
                    )}
                  </div>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Paper>
      <Dialog
        open={confirmationOpen}
        onClose={handleCancelUnlike}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to unlike this image? <br />
            This action will remove the image from your gallery.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmUnlike} autoFocus>
            Yes
          </Button>
          <Button onClick={handleCancelUnlike}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Gallery;
