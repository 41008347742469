import React from "react";
import ArrowLeftOnRectangleIcon from "@heroicons/react/24/solid/ArrowLeftOnRectangleIcon";
import PhotoIcon from "@heroicons/react/24/solid/PhotoIcon";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import LightBulbIcon from "@heroicons/react/24/solid/LightBulbIcon";
import PaintBrushIcon from "@heroicons/react/24/solid/PaintBrushIcon";
import HomeIcon from "@mui/icons-material/Home";

import { SvgIcon } from "@mui/material";

export const items = [
  {
    title: "How it works",
    path: "/about",
    icon: (
      <SvgIcon fontSize="small">
        <HomeIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Inkspiration",
    path: "/inkspiration",
    icon: (
      <SvgIcon fontSize="small">
        <LightBulbIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Portrait Tattoo",
    path: "/portrait",
    icon: (
      <SvgIcon fontSize="small">
        <PaintBrushIcon />
      </SvgIcon>
    ),
  },
  {
    title: "My Gallery",
    path: "/gallery",
    icon: (
      <SvgIcon fontSize="small">
        <PhotoIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Profile",
    path: "/profile",
    icon: (
      <SvgIcon fontSize="small">
        <UserIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Sign Out",
    path: "/",
    icon: (
      <SvgIcon fontSize="small">
        <ArrowLeftOnRectangleIcon />
      </SvgIcon>
    ),
  },
];
