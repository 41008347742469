import { Timestamp } from "@firebase/firestore-types";
import { collectionGroup, getDocs, getFirestore, query, where } from "@firebase/firestore";
import { GenerationService } from "../../services/generation-service";
import { TattooImg } from "../../../functions/src/types";

export interface ImageItem {
  img: string;
  title: string;
  date: Timestamp;
  is_liked?: boolean;
  prompt_id: string;
  img_id: string;
}

export async function fetchAndFormatImages(userId: string): Promise<ImageItem[]> {
  const db = getFirestore();
  const tattooImgCollectionGroup = collectionGroup(db, "tattoo_imgs");

  const q = query(
    tattooImgCollectionGroup,
    where("user_id", "==", userId),
    where("is_liked", "==", true),
  );

  const likedTattooImgs: Promise<ImageItem>[] = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    likedTattooImgs.push(
      new Promise((resolve, reject) => {
        const tattooImg = doc.data() as TattooImg;

        GenerationService.getTattooImgUrl(tattooImg, "1024")
          .then((imgUrl) => {
            if (imgUrl) {
              resolve({
                img: imgUrl,
                title: tattooImg.refined_prompt,
                date: tattooImg.created_at,
                is_liked: tattooImg.is_liked,
                prompt_id: tattooImg.prompt_id,
                img_id: tattooImg.img_id,
              });
            } else {
              reject("Failed to get image url");
            }
          })
          .catch((err) => reject(err));
      }),
    );
  });

  return Promise.all(likedTattooImgs);
}
