import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ImageCapture from "./image-capture";
import PortraitGeneration from "./portrait-generation";
import { Container, Snackbar, SnackbarContent } from "@mui/material";
import MethodSelection, { GenerationMethod } from "./method-selection";
import { GenerationService } from "../../services/generation-service";
import { useAuth } from "../../hooks/use-auth";
import { PORTRAIT_STYLES } from "../../../functions/src/constants";
import { useState } from "react";

const steps = ["Choose method", "Upload an image", "Done"];

export default function HorizontalNonLinearStepper() {
  const auth = useAuth();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [file, setFile] = React.useState<string | null>(null);
  const [method, setMethod] = React.useState<GenerationMethod | null>(null);

  const handleMethodChange = (method: GenerationMethod) => {
    setMethod(method);
    GenerationService.wakeUpServer()
      .then(() => console.log("initialized"))
      .catch((e) => console.log(e));

    handleNext();
  };

  const handleNext = () => {
    const newActiveStep = activeStep + 1;

    if (newActiveStep === 1 || newActiveStep === 2) {
      const userCredit = auth && auth.user ? auth.user.user_credit : 0;

      if (userCredit < PORTRAIT_STYLES.length) {
        setSnackbarMessage("Failed! Insufficient credits to proceed.");
        setSnackbarOpen(true);
        return;
      }
    }

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Container sx={{ width: "100%", mt: 5 }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              <Typography variant="subtitle1">{label}</Typography>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <Box sx={{ pt: 3, paddingX: { xs: 0, md: "16px" } }}>
          {activeStep === 0 && <MethodSelection setMethod={handleMethodChange} />}
          {activeStep === 1 && method && <ImageCapture setFile={setFile} method={method} />}
          {activeStep === 2 && file && <PortraitGeneration image={file} />}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
          {activeStep !== 1 && (
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              <Typography variant="subtitle1">Back</Typography>
            </Button>
          )}
          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep !== 2 && (
            <Button
              disabled={(activeStep === 0 && !method) || (activeStep === 1 && !file)}
              onClick={handleNext}
              sx={{ mr: 1 }}
            >
              <Typography variant="subtitle1">Next</Typography>
            </Button>
          )}
          {activeStep === 2 && file && (
            <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
              <Button onClick={handleReset}>Finish</Button>
            </Box>
          )}
        </Box>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <SnackbarContent
          sx={{
            backgroundColor: snackbarMessage.includes("Failed") ? "error.dark" : "success.dark",
          }}
          message={snackbarMessage}
        />
      </Snackbar>
    </Container>
  );
}
