import React, { useState, useEffect, createContext } from "react";
import { auth, db } from "../firebase"; // Adjust this import according to your project structure
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  updateProfile,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  getAdditionalUserInfo,
} from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import UserService from "../services/user-service";
import { SubscriptionService } from "../services/subscription-service";
import { generateUniqueUsername, registerUserInFirestore } from "../services/auth-service";
import { RegistrationType, User } from "../../functions/src/types";

export interface AuthUser extends User {
  username: string;
  photoUrl: string;
  emailVerified: boolean;
}

interface AuthContextType {
  user: AuthUser | null;
  isLoading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, displayName: string) => Promise<void>;
  signOut: () => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
}

const DEFAULT_PROFILE_IMAGE_URL = "/images/user.png";

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        const userDocRef = doc(db, "users", firebaseUser.uid);

        // Here we use onSnapshot instead of getDoc
        const unsubscribeDoc = onSnapshot(userDocRef, (doc) => {
          const customUserData = doc.data() as User;
          setUser(UserService.getAuthUser(customUserData, firebaseUser));
          SubscriptionService.refreshSubscription();
          setIsLoading(false);
        });

        return unsubscribeDoc; // This will unsubscribe from the document changes when the component unmounts
      } else {
        setUser(null);
        setIsLoading(false);
      }
    });

    return () => {
      unsubscribeAuth(); // Unsubscribe from auth changes when the component unmounts
    };
  }, []);

  const signIn = async (email: string, password: string) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const signUp = async (email: string, password: string, displayName: string) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const firebaseUser = userCredential.user;

    // Set the display name and the default profile image
    await updateProfile(firebaseUser, { displayName, photoURL: DEFAULT_PROFILE_IMAGE_URL });
  };

  const signOut = async () => {
    await firebaseSignOut(auth);
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await setPersistence(auth, browserLocalPersistence);
      const result = await signInWithPopup(auth, provider);
      const isNewUser = getAdditionalUserInfo(result)?.isNewUser;

      if (isNewUser) {
        const uid = result.user.uid;
        const userEmail = result.user.email;

        if (userEmail) {
          const username = await generateUniqueUsername();

          await registerUserInFirestore(uid, userEmail, RegistrationType.Google, username);
        } else {
          console.log("User email is null");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const forgotPassword = async (email: string) => {
    console.log("what is the eeemail", email);
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, isLoading, signIn, signUp, signOut, signInWithGoogle, forgotPassword }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
