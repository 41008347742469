import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import NavLayout from "../layouts/nav/layout";
import AccountProfile from "../components/account-profile/account-profile";
import AccountProfileDetails from "../components/account-profile/account-profile-details";
import PricingComponent from "../components/account-profile/pricing-plan";
import { GetTiersResponse } from "../../functions/src/types";
import { SubscriptionService } from "../services/subscription-service";

export interface Plan {
  id: string;
  title: string;
  description: string;
  monthlyPriceId: string;
  monthlyPrice: string;
  yearlyPriceId: string;
  yearlyPrice: string;
  features: string[];
}

const responseToState = (response: GetTiersResponse): Plan[] => {
  const plans: Plan[] = [];

  for (const tierId in response.tiers) {
    const tier = response.tiers[tierId];
    const groupedAmounts = _.keyBy(tier.amount, "interval");

    plans.push({
      id: tier.id,
      title: tier.label,
      description: tier.description,
      monthlyPriceId: groupedAmounts.month.id,
      monthlyPrice: `$${groupedAmounts.month.amount / 100}`,
      yearlyPriceId: groupedAmounts.year.id,
      yearlyPrice: `$${groupedAmounts.year.amount / 100}`,
      features: tier.features.map((f) => f.text),
    });
  }

  return plans;
};

const ProfilePage = () => {
  const [pricingPlan, setPricingPlan] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(true);

  // call the SubscriptionService to get the pricing plan once
  // the component is mounted
  useEffect(() => {
    SubscriptionService.getPricingPlans().then((pricingPlan) => {
      setPricingPlan(responseToState(pricingPlan));
      setLoading(false); // Set loading to false once data is loaded
    });
  }, []);

  return (
    <>
      <NavLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <Container maxWidth="lg">
            <Stack spacing={3}>
              <div>
                <Typography variant="h4">Account</Typography>
              </div>
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <AccountProfile />
                  </Grid>
                  <Grid item xs={12} md={6} lg={8}>
                    <AccountProfileDetails />
                  </Grid>
                </Grid>
              </div>
            </Stack>
          </Container>
          <Container maxWidth="lg" sx={{ mt: 10 }}>
            <Stack spacing={3}>
              <div>
                <Typography variant="h4">Plans & Pricing</Typography>
              </div>
              <div>
                <PricingComponent plan={pricingPlan} isLoading={loading} />
              </div>
            </Stack>
          </Container>
        </Box>
      </NavLayout>
    </>
  );
};

export default ProfilePage;
