import React from "react";
import PurchaseFailedMessage from "../components/pricing-messages/failed-message";
import ProfilePage from "./profilePage";

const FailedPuchasePage = () => {
  return (
    <>
      <PurchaseFailedMessage>
        <ProfilePage />
      </PurchaseFailedMessage>
    </>
  );
};

export default FailedPuchasePage;
