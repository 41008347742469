import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

import { keyframes } from "@emotion/react";

const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

function RedirectingMessage() {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      handleClose();
    }, 300000000000000000);

    return () => clearTimeout(redirectTimeout);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        sx={{
          marginLeft: {
            xs: 0,
            lg: "200px",
          },
        }}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "24px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              margin: "16px 0",
              animation: `${fadeInOut} 2s ease infinite`,
            }}
          >
            Redirecting...
          </Typography>

          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Hang on, you will be redirected soon.
          </Typography>

          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Thank you for your patience!
          </Typography>
          <SentimentSatisfiedAltIcon sx={{ color: "#6366F1", mt: 2 }} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default RedirectingMessage;
