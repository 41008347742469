import React, { useCallback, useState } from "react";
import { Box, Paper, Typography, IconButton, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface DragDropFileUploadProps {
  onFileUpload: (image: string) => void;
  onClick: () => void;
}

const DragDropFileUpload: React.FC<DragDropFileUploadProps> = ({ onFileUpload, onClick }) => {
  const [dragOver, setDragOver] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleFileChange = useCallback(
    (file: File) => {
      setLoading(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLoading(false);
        setImagePreview(reader.result as string);
        onFileUpload(reader.result as string);
      };
      reader.readAsDataURL(file);
    },
    [onFileUpload],
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setDragOver(false);
      const files = event.dataTransfer.files;
      if (files && files[0]) {
        handleFileChange(files[0]);
      }
    },
    [handleFileChange],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onClick();
      const files = event.target.files;
      if (files && files[0]) {
        handleFileChange(files[0]);
      }
    },
    [handleFileChange, onClick],
  );

  return (
    <Box>
      <Paper
        variant="outlined"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          border: dragOver ? "2px dashed #000" : "2px dashed #aaa",
          padding: 20,
          textAlign: "center",
          cursor: "pointer",
          background: dragOver ? "#eee" : "#fafafa",
          position: "relative",
        }}
      >
        {imagePreview && (
          <Box
            display="flex" // Use flexbox
            alignItems="center" // Align items vertically in the center
            justifyContent="center" // Align items horizontally in the center
            height="100%" // Set the height to fill the container
            sx={{ width: "100%" }} // Set the width to fill the container
          >
            <img
              src={imagePreview}
              alt="your tattoo"
              style={{ maxWidth: "512px", maxHeight: "100%", width: "auto", height: "auto" }}
            />
          </Box>
        )}
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleChange}
        />
        {!imagePreview && (
          <label htmlFor="raised-button-file">
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton color="primary" aria-label="upload picture" component="span">
                <CloudUploadIcon style={{ fontSize: 60 }} />
              </IconButton>
              <Typography>Drag and drop files here or click to select files</Typography>
            </Box>
          </label>
        )}
        {loading && (
          <CircularProgress
            size={24}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Paper>
    </Box>
  );
};

export default DragDropFileUpload;
