import * as React from "react";
import { useEffect, useRef } from "react";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";

import { TattooImg } from "../../../functions/src/types";
import { GenerationService } from "../../services/generation-service";
import { useAuth } from "../../hooks/use-auth";
import TattooImgComponent from "./tattoo-img";

interface StepTwoProps {
  image: string;
}

export default function PortraitGeneration({ image }: StepTwoProps) {
  const [tattooImgs, setTattooImgs] = React.useState<TattooImg[]>([]);
  const auth = useAuth();

  // DON'T TOUCH THIS CODE, AS IT CAN GET EXPENSIVE (spending all the credits)
  // HAPPENED TO ME TWICE
  const hasRun = useRef(false);
  useEffect(() => {
    if (!hasRun.current && auth.user) {
      hasRun.current = true;
      GenerationService.createPortraitTattoos(auth.user.uid, image).then((imgs) => {
        setTattooImgs(imgs);
      });
    }
  }, [auth.user, image]); // dependencies included

  return (
    <Container maxWidth="lg" sx={{ pt: 6 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Generate Images
        </Typography>
      </Box>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container alignItems="stretch">
          {/* Image Grid Item */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "2rem",
            }}
          >
            <img
              src={image}
              alt="uploaded"
              style={{
                maxWidth: "100%",
                height: "auto",
                objectFit: "contain",
                borderRadius: "8px",
              }}
            />
          </Grid>

          {/* Text Grid Item */}
          <Grid item xs={12} md={6} sx={{ marginBottom: "2rem" }}>
            <Paper elevation={3} sx={{ textAlign: "top", height: "100%", padding: "1rem" }}>
              <div
                style={{
                  height: "100%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h4" sx={{ mb: "1rem" }}>
                  Generating Info
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "left" }}>
                  <ul>
                    <li style={{ marginBottom: "0.8rem" }}>
                      Please hang on, keep in mind that the process may take a couple of minutes.
                    </li>
                    <li style={{ marginBottom: "0.8rem" }}>
                      After the images are generated, you can save your favorite ones to the gallery
                      by hitting the heart icon next to each image you like
                    </li>
                    <li style={{ marginBottom: "0.8rem" }}>
                      You can find all liked images in the{" "}
                      <a
                        href="/gallery"
                        style={{ cursor: "pointer", color: "black", textDecoration: "none" }}
                      >
                        <b>gallery</b>
                      </a>
                    </li>
                    <li style={{ marginBottom: "0.8rem" }}>
                      Please note that all images which are not liked will be lost.
                    </li>
                  </ul>
                </Typography>
                <Typography variant="h6" sx={{ textAlign: "center", mt: "1rem" }}>
                  Thank you!
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>

        {/* Tattoo images */}
        <Grid item container spacing={2}>
          {tattooImgs.map((item, i) => (
            <Grid item key={i} xs={12} sm={6} md={4} lg={4} xl={4}>
              <TattooImgComponent tattooImg={item} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}
