import React, { useEffect, useState } from "react";
import { db } from "../../firebase"; // Import your firebase configuration
import { onSnapshot, doc, updateDoc } from "firebase/firestore";
import { TattooImg } from "../../../functions/src/types";
import { Skeleton, ImageListItem, ImageListItemBar } from "@mui/material";
import { GenerationService } from "../../services/generation-service";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ImageModal from "../img-modal/img-modal";

interface Props {
  tattooImg: TattooImg;
}

const TattooImgComponent = ({ tattooImg }: Props) => {
  const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);
  const [highResImgUrl, setHighResImgUrl] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOpen = async () => {
    if (!highResImgUrl) {
      // Only fetch if highResImgUrl is not already set
      const url = await GenerationService.getTattooImgUrl(tattooImg, "1024");
      setHighResImgUrl(url);
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const tattooImgRef = doc(db, "tattoos", tattooImg.prompt_id, "tattoo_imgs", tattooImg.img_id);

    const unsubscribe = onSnapshot(tattooImgRef, async (docSnapshot) => {
      if (docSnapshot.exists()) {
        const updatedTattooImg = docSnapshot.data() as TattooImg;
        if (!updatedTattooImg.is_loading) {
          const url = await GenerationService.getTattooImgUrl(updatedTattooImg);
          console.log(`url: ${url}`);
          setImgUrl(url);
          setLoading(false);
        }
      }
    });

    return () => unsubscribe();
  }, [tattooImg.is_loading, tattooImg.img_id, tattooImg.prompt_id]);

  const toggleLike = async () => {
    setIsLiked(!isLiked);
    const tattooImgRef = doc(db, "tattoos", tattooImg.prompt_id, "tattoo_imgs", tattooImg.img_id);
    await updateDoc(tattooImgRef, { is_liked: !isLiked });
  };

  const promptStyle = tattooImg.refined_prompt.split(" ").pop();
  const imageStyle = promptStyle?.replace("ink", "").replace("style", "");

  return (
    <ImageListItem key={tattooImg.img_id} sx={{ borderRadius: "8px" }}>
      {loading
        ? [
            <Skeleton
              variant="rectangular"
              width="100%"
              height={333}
              sx={{ borderRadius: "8px" }}
            />,
          ]
        : [
            <img
              key="image"
              src={imgUrl}
              alt={imageStyle}
              onClick={handleOpen}
              style={{ borderRadius: "8px", cursor: "pointer", width: "100%" }}
            />,
            <ImageListItemBar
              key="imageBar"
              title={imageStyle}
              actionIcon={
                <div
                  style={{
                    borderRadius: "50%",
                    paddingTop: "8px",
                    paddingRight: "15px",
                    cursor: "pointer",
                  }}
                  onClick={toggleLike}
                >
                  {isLiked ? (
                    <FavoriteIcon style={{ color: "red" }} />
                  ) : (
                    <FavoriteBorderIcon style={{ color: "white" }} />
                  )}
                </div>
              }
              sx={{ borderRadius: "8px" }}
            />,
            <ImageModal
              imgUrl={highResImgUrl}
              open={open}
              handleClose={handleClose}
              refined_prompt={tattooImg.refined_prompt}
            />,
          ]}
    </ImageListItem>
  );
};
export default TattooImgComponent;
