import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import Layout from "../layouts/auth/layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../hooks/use-auth";

const RegisterPage = () => {
  const auth = useAuth();
  if (auth.user) {
    window.location.href = "/about";
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
      name: Yup.string().max(255).required("Name is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        if (values.email && values.password) {
          await auth.signUp(values.email, values.password, values.name);
          window.location.href = "/gallery";
        } else {
          await auth.signInWithGoogle();
          window.location.href = "/gallery";
        }
      } catch (error) {
        console.log("error", error);
        if (error.code === "auth/invalid-email") {
          helpers.setFieldError("email", "Invalid email");
        }
        if (error.code === "auth/weak-password") {
          helpers.setFieldError("password", "Password should be at least 6 characters");
        } else {
          helpers.setFieldError("submit", "Something went wrong. Try again.");
        }
      }
    },
  });
  return (
    <Layout>
      <Box
        sx={{
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: "100px",
            width: "100%",
          }}
        >
          <div>
            <Typography variant="h4" sx={{ mb: "8px" }}>
              Register
            </Typography>
            <Typography color="text.secondary" variant="body2" sx={{ mb: "40px" }}>
              Already have an account? &nbsp;
              <Link component="a" href="/" underline="hover" variant="subtitle2">
                Login
              </Link>
            </Typography>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.name && formik.errors.name)}
                  fullWidth
                  helperText={formik.touched.name && formik.errors.name}
                  label="Name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email Address"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                />
                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                />
              </Stack>
              {formik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {formik.errors.submit}
                </Typography>
              )}
              <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
                Continue
              </Button>
              <Button
                fullWidth
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
                onClick={auth.signInWithGoogle}
              >
                Sign Up With Google
              </Button>
            </form>
          </div>
        </Box>
      </Box>
    </Layout>
  );
};

export default RegisterPage;
