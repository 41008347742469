import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  SvgIcon,
  Container,
  Divider,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const CreateTatooSteps = () => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 2,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{ background: "rgb(245, 247, 255)", padding: "15px", borderRadius: "8px" }}
      >
        <Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
          How It Works
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <Card>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography color="text.secondary" variant="overline">
                      Step 1
                    </Typography>
                  </div>
                  <Avatar
                    sx={{
                      backgroundColor: "#6366F1",
                      height: 56,
                      width: 56,
                    }}
                  >
                    <SvgIcon>
                      <KeyboardArrowRightIcon />
                    </SvgIcon>
                  </Avatar>
                </div>
                <Divider sx={{ my: 2 }} />
                <ul style={{ paddingLeft: 15 }}>
                  <li>
                    <Typography variant="h6" sx={{ listStyleType: "circle" }}>
                      Reach portrait/inkspiration
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h6">Insert the prompt message</Typography>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Card>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography color="text.secondary" variant="overline">
                      Step 2
                    </Typography>
                  </div>
                  <Avatar
                    sx={{
                      backgroundColor: "#6366F1",
                      height: 56,
                      width: 56,
                    }}
                  >
                    <SvgIcon style={{ marginRight: "-5px" }}>
                      <KeyboardArrowRightIcon />
                    </SvgIcon>
                    <SvgIcon style={{ marginLeft: "-5px" }}>
                      <KeyboardArrowRightIcon />
                    </SvgIcon>
                  </Avatar>
                </div>
                <Divider sx={{ my: 2 }} />
                <ul style={{ paddingLeft: 15 }}>
                  <li>
                    <Typography variant="h6" sx={{ listStyleType: "circle" }}>
                      Verify your available credit
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h6">Start the process</Typography>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Card>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography color="text.secondary" variant="overline">
                      Step 3
                    </Typography>
                  </div>
                  <Avatar
                    sx={{
                      backgroundColor: "#6366F1",
                      height: 56,
                      width: 56,
                    }}
                  >
                    <SvgIcon style={{ marginRight: "-10px" }}>
                      <KeyboardArrowRightIcon />
                    </SvgIcon>
                    <SvgIcon style={{ margin: "0" }}>
                      <KeyboardArrowRightIcon />
                    </SvgIcon>
                    <SvgIcon style={{ marginLeft: "-10px" }}>
                      <KeyboardArrowRightIcon />
                    </SvgIcon>
                  </Avatar>
                </div>
                <Divider sx={{ my: 2 }} />
                <ul style={{ paddingLeft: 15 }}>
                  <li>
                    <Typography variant="h6" sx={{ listStyleType: "circle" }}>
                      Check the generated images
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h6">Enjoy! </Typography>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CreateTatooSteps;
