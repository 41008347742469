import React, { useState } from "react";
import {
  Box,
  Typography,
  Link,
  Stack,
  TextField,
  Button,
  Alert,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import Layout from "../layouts/auth/layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../hooks/use-auth";

const LoginPage = () => {
  const auth = useAuth();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  if (auth.user) {
    window.location.href = "/about";
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        if (values.email && values.password) {
          // Email/password sign-in
          await auth.signIn(values.email, values.password);
          window.location.href = "/gallery";
        } else {
          // Google sign-in
          await auth.signInWithGoogle();
          window.location.href = "/gallery";
        }
      } catch (error) {
        if (error.code === "auth/wrong-password") {
          helpers.setFieldError("password", "Invalid password");
        } else {
          helpers.setFieldError("submit", "Invalid email or password");
        }
      }
    },
  });

  const handleForgotPassword = async () => {
    if (!formik.values.email) {
      setSnackbarMessage(" Failed Please enter your email.");
      setSnackbarOpen(true);
      return;
    }

    try {
      await auth.forgotPassword(formik.values.email);
      setSnackbarMessage("Password reset email sent successfully.");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Failed to send password reset email.");
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Layout>
        <Box
          sx={{
            background: "background.paper",
            flex: "1 1 auto",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: 550,
              px: 3,
              py: "100px",
              width: "100%",
            }}
          >
            <Typography variant="h4" sx={{ mb: "8px" }}>
              Login
            </Typography>
            <Typography color="text.secondary" variant="body2" sx={{ mb: "40px" }}>
              Don&apos;t have an account? &nbsp;
              <Link component="a" href="/register" underline="hover" variant="subtitle2">
                Register
              </Link>
            </Typography>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email Address"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                />
                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                />
                <Typography color="text.secondary" variant="body2" sx={{ mb: "40px" }}>
                  Forgot your password? &nbsp;
                  <Link
                    component="a"
                    underline="hover"
                    variant="subtitle2"
                    onClick={handleForgotPassword}
                    sx={{ cursor: "pointer" }}
                  >
                    Click here
                  </Link>
                </Typography>
              </Stack>
              {formik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {formik.errors.submit}
                </Typography>
              )}
              <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
                Continue
              </Button>
              <Button
                fullWidth
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
                onClick={auth.signInWithGoogle}
              >
                Sign In With Google
              </Button>
              <Alert color="info" severity="info" sx={{ mt: 3 }}>
                <div>
                  Thank you for choosing <b>Inkcredible!</b>
                </div>
              </Alert>
            </form>
          </Box>
        </Box>
      </Layout>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <SnackbarContent
          sx={{
            backgroundColor: snackbarMessage.includes("Failed") ? "error.dark" : "success.dark",
          }}
          message={snackbarMessage}
        />
      </Snackbar>
    </>
  );
};

export default LoginPage;
