import React from "react";
import { Box, Typography, Paper } from "@mui/material";

const AboutHeader = () => {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, py: 8, px: 3 }}>
        <Box
          sx={{ flex: 1, p: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}
        >
          <Typography variant="h4">Our Inkcredible story</Typography>
          <Box sx={{ mt: 3 }}>
            <Typography variant="body1">
              Welcome to our website, where art meets technology. At our platform, we create unique
              tattoos that are a perfect blend of your creativity and our advanced AI technology.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Our innovative process starts with your prompts and ideas. You provide the
              inspiration, and our AI algorithms work tirelessly to create a personalized tattoo
              design just for you.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              We take pride in the seamless fusion of human creativity and artificial intelligence,
              resulting in stunning and one-of-a-kind tattoo designs that reflect your
              individuality.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            flex: 1,
            p: {
              xs: 1,
              lg: 3,
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper elevation={3} sx={{ p: 1 }}>
            <img
              src="/images/about_image.png"
              alt="Generated Tattoo Design"
              style={{ width: "100%", height: "auto", borderRadius: "8px" }}
            />
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default AboutHeader;
