export function filterImagesByDate(filterValue, itemData) {
  if (filterValue === "") {
    return itemData;
  } else {
    const currentDate = new Date();
    const filtered = itemData.filter((item) => {
      const itemDate = new Date(item.date);
      if (filterValue === "today") {
        return itemDate.toDateString() === currentDate.toDateString();
      } else if (filterValue === "this_week") {
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6);
        return itemDate >= startOfWeek && itemDate <= endOfWeek;
      } else if (filterValue === "this_month") {
        return (
          itemDate.getMonth() === currentDate.getMonth() &&
          itemDate.getFullYear() === currentDate.getFullYear()
        );
      }
      return false;
    });
    return filtered;
  }
}
