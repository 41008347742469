import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import PaymentIcon from "@mui/icons-material/Payment";
import Typography from "@mui/material/Typography";

function PurchaseSuccessfulMessage({ children }) {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        sx={{
          marginLeft: {
            xs: 0,
            lg: "200px",
          },
        }}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "24px",
          }}
        >
          <PaymentIcon sx={{ fontSize: 60, color: "#6366F1" }} />
          <Typography variant="h5" sx={{ margin: "16px 0" }}>
            Payment Successful!
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Your payment has been processed successfully.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "8px",
          }}
        >
          <Button variant="contained" onClick={handleClose} sx={{ mb: 2 }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </>
  );
}

export default PurchaseSuccessfulMessage;
