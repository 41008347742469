import { httpsCallable } from "firebase/functions";
import { functions, storage } from "../firebase";
import {
  PortraitTattooRequest,
  PortraitTattooResponse,
  Resolution,
  TattooImg,
} from "../../functions/src/types";
import { getDownloadURL, ref } from "firebase/storage";

export function createPath(
  userId: string,
  promptId: string,
  generationId: string,
  resolution: Resolution,
): string {
  const segments = ["tattoos_user", userId, promptId, `${generationId}_${resolution}.jpg`];

  return segments.join("/");
}

export class GenerationService {
  static async createPortraitTattoos(userId: string, image: string): Promise<TattooImg[]> {
    const refreshTokenFunction = httpsCallable<PortraitTattooRequest, PortraitTattooResponse>(
      functions,
      "generatePortraitTattoo",
    );

    const response = await refreshTokenFunction({ user_id: userId, image });

    if (response.data) {
      const { data, error, errorMsg } = response.data;
      if (error) {
        console.log(errorMsg);
        throw new Error(errorMsg);
      } else {
        return data;
      }
    } else {
      throw new Error("No response from server");
    }
  }

  static async wakeUpServer(): Promise<void> {
    const wakeUpFunction = httpsCallable<{}, {}>(functions, "wakeUpServer");
    await wakeUpFunction({});
  }

  static async getTattooImgUrl(
    tattooImg: TattooImg,
    size: Resolution = "256",
  ): Promise<string | undefined> {
    try {
      const path = createPath(tattooImg.user_id, tattooImg.prompt_id, tattooImg.img_id, size);
      const imgRef = ref(storage, path);
      return await getDownloadURL(imgRef);
    } catch (e) {
      console.log(e);
    }
  }
}
