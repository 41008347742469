import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/loginPage";
import RegisterPage from "./pages/registerPage";
import ProfilePage from "./pages/profilePage";
import { useAuth } from "./hooks/use-auth";
import SuccessPuchasePage from "./pages/successPurchasePage";
import FailedPuchasePage from "./pages/failedPurchasePage";
import AboutPage from "./pages/aboutPage";
import GalleryPage from "./pages/galleryPage";
import PortraitPage from "./pages/portraitPage";

export const AppRoutes = () => {
  const auth = useAuth();
  const user = auth.user;

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        {user ? (
          <>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/portrait" element={<PortraitPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/profile/success" element={<SuccessPuchasePage />} />
            <Route path="/profile/failed" element={<FailedPuchasePage />} />
            <Route path="/gallery" element={<GalleryPage />} />
          </>
        ) : null}
      </Routes>
    </Router>
  );
};
