import { Grid } from "@mui/material";
import * as React from "react";
import DragDropFileUpload from "./drag-and-drop";
import WebcamBox from "./webcam-box";
import { GenerationMethod } from "./method-selection";

interface StepOneProps {
  setFile: (image: string) => void;
  method: GenerationMethod;
}

export default function ImageCapture({ setFile, method }: StepOneProps) {
  const handleFileUpload = (image: string) => {
    setFile(image);
  };

  return (
    <Grid container spacing={10} sx={{ pt: 5 }}>
      {method === GenerationMethod.DragAndDrop && (
        <Grid item xs={12} sm={12} lg={12}>
          <DragDropFileUpload
            onFileUpload={handleFileUpload}
            onClick={() => console.log("clicked")}
          />
        </Grid>
      )}
      {method === GenerationMethod.Webcam && (
        <Grid item xs={12} sm={12} lg={12}>
          <WebcamBox onFileUpload={handleFileUpload} />
        </Grid>
      )}
    </Grid>
  );
}
