import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  Button,
  Grid,
  Switch,
  FormControlLabel,
  Box,
  Divider,
  Skeleton,
} from "@mui/material";

import { SubscriptionService } from "../../services/subscription-service";
import { useAuth } from "../../hooks/use-auth";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { AuthUser } from "../../contexts/auth-context";
import { Plan } from "../../pages/profilePage";
import RedirectingMessage from "../redirect-message/redirect-message";
import UserService from "../../services/user-service";

const PricingPlan = ({ plan, isLoading }: { plan: Plan[]; isLoading: boolean }) => {
  const [annualBilling, setAnnualBilling] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const auth = useAuth();
  const user = auth.user;
  const hasSubscription = user ? UserService.hasSubscription(user) : false;

  if (!user) {
    return null;
  }

  const handleSelectedPlan = async (plan: Plan, user: AuthUser) => {
    setRedirecting(true);
    if (hasSubscription) {
      const stripeLink = await SubscriptionService.getStripeLink(user);
      if (stripeLink) window.location.assign(stripeLink);
    } else {
      const priceId = annualBilling ? plan.yearlyPriceId : plan.monthlyPriceId;
      const docRef = await SubscriptionService.createCheckoutSession(user.uid, priceId);

      onSnapshot(doc(db, "users", user.uid, "checkout_sessions", docRef.id), (snap) => {
        const snapData = snap.data();
        if (!snapData) {
          return;
        }

        const { error, url } = snapData;
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occurred: ${error.message}`);
        }
        if (url) {
          // We have a Stripe Checkout URL, let's redirect.
          window.location.assign(url);
        }
      });
    }
  };

  return (
    <div
      style={{
        background: "linear-gradient(to bottom, #1C2536 60%, #ffffff 50%)",
        borderRadius: "8px",
      }}
    >
      <Typography sx={{ color: "white", textAlign: "center", pt: 4 }}>
        Discover the Perfect Plan for Your Tattoo Artistry with <b>INKCREDIBLE</b>
      </Typography>
      <Box display="flex" justifyContent="center" mb={2} p={2}>
        <FormControlLabel
          control={
            <Switch
              checked={annualBilling}
              onChange={() => setAnnualBilling(!annualBilling)}
              color="primary"
              sx={{
                "& .MuiSwitch-thumb": {
                  backgroundColor: "white",
                },
                "& .MuiSwitch-track": {
                  backgroundColor: "white",
                },
              }}
            />
          }
          label={
            <span style={{ color: "white" }}>
              {" "}
              {annualBilling ? "Yearly Billing" : "Monthly Billing"}{" "}
            </span>
          }
        />
      </Box>
      {isLoading ? (
        <Grid container spacing={3} p={2}>
          {[...Array(3)].map((_, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              key={index}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  bgcolor: "background.paper",
                  borderRadius: "8px",
                  overflow: "hidden",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Skeleton variant="rectangular" width={360} height={330} />
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={3} p={2}>
          {plan.map((plan, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <CardHeader title={plan.title} />
                <Typography>{plan.description}</Typography>
                <CardContent sx={{ flex: 1 }}>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    {annualBilling ? plan.yearlyPrice : plan.monthlyPrice}
                  </Typography>
                  {annualBilling && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "red",
                        fontSize: "0.8rem",
                      }}
                    >
                      {" Save up to 20%"}
                    </Typography>
                  )}
                  <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      disabled={plan.id === user.tier}
                      sx={{
                        mb: 2,
                        mt: 2,
                      }}
                      onClick={() => handleSelectedPlan(plan, user)}
                    >
                      {hasSubscription
                        ? plan.id === user.tier
                          ? "Current Plan"
                          : "Modify"
                        : "Select"}
                    </Button>
                  </CardActions>
                  <Divider sx={{ width: "240px" }} />
                </CardContent>
                {plan.features.map((feature, i) => (
                  <li key={i} style={{ marginBottom: "16px" }}>
                    {feature}
                  </li>
                ))}
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {redirecting && <RedirectingMessage />}
    </div>
  );
};

export default PricingPlan;
