// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_PUBLIC_API_KEY,
  authDomain: process.env.REACT_APP_PUBLIC_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PUBLIC_DATABASE_URL,
  projectId: process.env.REACT_APP_PUBLIC_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PUBLIC_APP_ID,
  measurementId: process.env.REACT_APP_PUBLIC_MEASUREMENT_ID,
};

const useEmulator = process.env.REACT_APP_USE_EMULATOR === "true";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, "us-central1");

// Configure Firebase services to connect to emulators if useEmulator is true
if (useEmulator) {
  connectFirestoreEmulator(db, "localhost", 8081);
  connectAuthEmulator(auth, "http://localhost:9099");
  // connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

// Other configurations remain the same
const isProd = firebaseConfig.projectId === "inkcred-prod";
const getAppConfig = (isProd: boolean) => {
  if (isProd) {
    return {
      apiBaseUrl: "https://us-central1-inkcred-prod.cloudfunctions.net",
    };
  } else {
    return {
      apiBaseUrl: "https://us-central1-inkcred-dev.cloudfunctions.net",
    };
  }
};

const config = getAppConfig(isProd);

export { db, auth, storage, app, functions, config };
