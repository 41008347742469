import { httpsCallable } from "firebase/functions";
import { db, functions } from "../firebase";
import { GetTiersResponse, RefreshTokenResponse } from "../../functions/src/types";
import { addDoc, collection } from "@firebase/firestore";
import { AuthUser } from "../contexts/auth-context";

interface StripeRequest {
  customerId: string;
  returnUrl: string;
}

export class SubscriptionService {
  static async getPricingPlans(): Promise<GetTiersResponse> {
    const functionRef = httpsCallable<undefined, GetTiersResponse>(functions, "getTiers");
    const response = await functionRef();
    return response.data;
  }

  static createCheckoutSession(userId: string, priceId: string) {
    const cancelUrl = `${window.origin}/profile/failed`;
    const successUrl = `${window.origin}/profile/success`;

    return addDoc(collection(db, "users", userId, "checkout_sessions"), {
      price: priceId,
      success_url: successUrl,
      cancel_url: cancelUrl,
    });
  }

  static async getStripeLink(user: AuthUser) {
    // check if user has an active subscription
    if (user.tier && user.tier !== "free" && user.tier !== "trial" && user.stripeId) {
      try {
        const functionRef = httpsCallable<StripeRequest, { url: string }>(
          functions,
          "ext-firestore-stripe-payments-createPortalLink",
        );
        const result = await functionRef({
          customerId: user.stripeId,
          returnUrl: window.location.origin,
        });

        if (result && result.data && result.data.url) {
          return result.data.url;
        }

        throw new Error("No url returned from function");
      } catch (error) {
        console.error("Failed to get stripe link:", error);
      }
    }
  }

  static async refreshSubscription() {
    const refreshTokenFunction = httpsCallable<undefined, RefreshTokenResponse>(
      functions,
      "refreshToken",
    );

    try {
      // The function updates the token in Firestore, and the onSnapshot below
      // will trigger an update in your component once that happens.
      const response = await refreshTokenFunction();
      const { error, errorCode, errorMsg } = response.data;
      if (error) {
        throw new Error(`Failed to refresh token: ${errorCode} - ${errorMsg}`);
      }
    } catch (error) {
      console.error("Failed to refresh token:", error);
    }
  }
}
