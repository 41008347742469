import { Timestamp } from "@firebase/firestore-types";

export enum TattooType {
  Img2Img = "Img2Img",
  Portrait = "Portrait",
}

export interface ApiKey {
  key: string;
  name: string;
  createdAt: Timestamp;
}

export interface Tattoo {
  prompt_id: string;
  prompt_text: string;
  created_at: Timestamp;
  modified_at: Timestamp;
  user_id: string;
  type: TattooType;
  filePath?: string; // path to the source image
}

export interface Comment {
  user_id: string;
  text: string;
  created_at: Timestamp;
}

export interface ExtendedComment extends Comment {
  username: string; // added by szeka, once implemented make it required
  profile_image: string;
}

export interface TattooImg {
  img_id: string;
  user_id: string;
  refined_prompt: string;
  is_loading: boolean;
  prompt_id: string;
  // img_url: string | undefined;
  // path_to_img: string;  there are multiple paths to img, so this is not needed
  params: Dictionary<any>;
  created_at: Timestamp;
  // subDocId?: string;
  comments?: Comment[];
  is_liked?: boolean;
}

export interface ImageModalProps {
  open: boolean;
  handleClose: () => void;
  imgUrl: string | undefined;
  refined_prompt?: string;
}

export interface Design {
  id: string;
  name: string;
  img_url: string;
}

export interface Dictionary<T> {
  [key: string]: T;
}

export interface UserCache {
  username: string;
  profile_image: string;
}

export interface RawUser {
  uid: string;
  email: string;
}

export type User = {
  created_at: Timestamp;
  email: string;
  // emailVerified: boolean;
  is_admin: boolean;
  profile_picture_path: string;
  registration_type: RegistrationType;
  tier: string;
  subscription_interval?: "month" | "year";
  subscription_expires_at?: Timestamp;
  uid: string;
  user_credit: number;
  last_credit_updated_at?: Timestamp;
  personal_details?: PersonalDetails;
  stripeId?: string;
  raw_user: RawUser;
};

export interface PersonalDetails {
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  address?: Address;
}

export interface Address {
  country?: string;
  state_province?: string;
  city?: string;
  street?: string;
  postal_code?: string;
}

export enum RegistrationType {
  Facebook = "facebook",
  Google = "google",
  Email = "email",
}

export interface RefreshTokenResponse {
  error: boolean;
  errorCode?: string;
  errorMsg?: string;
}

export interface GetTiersResponse {
  success: boolean;
  error?: string;
  tiers: Dictionary<Tier>;
}

export interface Feature {
  text: string;
  isLive: boolean;
}

export interface Amount {
  id: string;
  currency: string;
  interval: "month" | "year";
  amount: number;
}

export interface Tier {
  id: string;
  label: string;
  description: string;
  amount: Amount[];
  token: number;
  features: Feature[];
}

export interface PortraitTattooResponse {
  error: boolean;
  errorCode?: string;
  errorMsg?: string;
  data: TattooImg[];
}

export interface PortraitTattooRequest {
  user_id: string;
  image: string;
}

export type Resolution = "1024" | "512" | "256";
