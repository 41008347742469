import React from "react";
import NavLayout from "../layouts/nav/layout";
import CreateTatooSteps from "../components/about-header/steps";
import AboutHeader from "../components/about-header/about-header";

const AboutPage = () => {
  return (
    <>
      <NavLayout>
        <AboutHeader />
        <CreateTatooSteps />
      </NavLayout>
    </>
  );
};

export default AboutPage;
