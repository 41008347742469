import React from "react";
import PurchaseSuccessfulMessage from "../components/pricing-messages/successfull-message";
import ProfilePage from "./profilePage";

const SuccessPurchasePage = () => {
  return (
    <>
      <PurchaseSuccessfulMessage>
        <ProfilePage />
      </PurchaseSuccessfulMessage>
    </>
  );
};

export default SuccessPurchasePage;
