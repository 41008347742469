import React, { useEffect } from "react";
import { useCallback, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { useAuth } from "../../hooks/use-auth";
import { AuthUser } from "../../contexts/auth-context";
import UserService from "../../services/user-service";

// some small utils
const userToForm = (user: AuthUser | null) => {
  return {
    firstName: user?.personal_details?.first_name || "",
    lastName: user?.personal_details?.last_name || "",
    email: user?.email || "",
    phone: user?.personal_details?.phone_number || "",
    state: user?.personal_details?.address?.city || "",
    country: user?.personal_details?.address?.country || "",
  };
};

const formToUser = (form: any, user: AuthUser): AuthUser => {
  return {
    ...user,
    email: form.email,
    personal_details: {
      ...user?.personal_details,
      first_name: form.firstName,
      last_name: form.lastName,
      phone_number: form.phone,
      address: {
        ...user?.personal_details?.address,
        city: form.state,
        country: form.country,
      },
    },
  };
};

const AccountProfileDetails = () => {
  const auth = useAuth();
  const user = auth.user;

  const [values, setValues] = useState(userToForm(user));

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (user) {
        const updatedAuthUser = formToUser(values, user);
        const updatedUser = UserService.getUserFromAuthUser(updatedAuthUser);
        await UserService.update(updatedUser);
        setValues(userToForm(updatedAuthUser));
      }
    },
    [values, user],
  );

  useEffect(() => {
    if (user) {
      setValues(userToForm(user));
    }
  }, [user]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Card>
        <CardHeader subheader="Please complete your profile information" title="Profile" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="First name"
                  name="firstName"
                  onChange={handleChange}
                  required
                  value={values.firstName}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Last name"
                  name="lastName"
                  onChange={handleChange}
                  required
                  value={values.lastName}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  required
                  value={values.email}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  onChange={handleChange}
                  type="number"
                  value={values.phone}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Country"
                  name="country"
                  onChange={handleChange}
                  required
                  value={values.country}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="State"
                  name="state"
                  onChange={handleChange}
                  required
                  value={values.state}
                ></TextField>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button type="submit" variant="contained">
            Save details
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default AccountProfileDetails;
