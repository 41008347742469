import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { items } from "./items";
import { Box, ButtonBase } from "@mui/material";
import { useLocation } from "react-router-dom";

const NavItems = () => {
  const nav_items = items;
  const location = useLocation();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  //We use useEffect to keep track of the active index on page change
  useEffect(() => {
    const currentIndex = nav_items.findIndex((item) => item.path === location.pathname);
    setActiveIndex(currentIndex);
  }, [location.pathname, nav_items]);

  const handleItemClick = (index: number, path: string) => {
    setActiveIndex(index);
    navigate(path);
  };
  return (
    <li>
      {nav_items.map((item, index) => (
        <ButtonBase
          key={index}
          sx={{
            alignItems: "center",
            borderRadius: 1,
            display: "flex",
            justifyContent: "flex-start",
            pl: "16px",
            pr: "16px",
            py: "6px",
            mb: "8px",
            textAlign: "left",
            width: "100%",
            ...(activeIndex === index && {
              backgroundColor: "rgba(255, 255, 255, 0.04)",
            }),
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.04)",
            },
          }}
          onClick={() => handleItemClick(index, item.path)}
        >
          <Box
            component="span"
            sx={{
              alignItems: "center",
              display: "inline-flex",
              justifyContent: "center",
              mr: 2,
              color: activeIndex === index ? "primary.main" : "neutral.400",
            }}
          >
            {item.icon}
          </Box>
          <Box
            component="span"
            sx={{
              color: activeIndex === index ? "common.white" : "neutral.400",
              flexGrow: 1,
              fontFamily: (theme) => theme.typography.fontFamily,
              fontSize: 14,
              fontWeight: 600,
              lineHeight: "24px",
              whiteSpace: "nowrap",
            }}
          >
            {item.title}
          </Box>
        </ButtonBase>
      ))}
    </li>
  );
};

export default NavItems;
