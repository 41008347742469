import { User as FirebaseUser } from "firebase/auth";
import { AuthUser } from "../contexts/auth-context";
import { db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { User } from "../../functions/src/types";

export default class UserService {
  static getAuthUser(user: User, firebaseUser: FirebaseUser): AuthUser {
    const username = firebaseUser.displayName;
    const photoURL = firebaseUser.photoURL;

    if (!username) throw new Error("Username is not set");
    if (!photoURL) throw new Error("Photo URL is not set");

    return {
      ...user,
      username: username,
      photoUrl: photoURL,
      emailVerified: firebaseUser.emailVerified,
    };
  }

  static getUserFromAuthUser(authUser: AuthUser): User {
    const { username, photoUrl, ...rest } = authUser;
    return rest;
  }

  static async update(user: User) {
    const userDocRef = doc(db, "users", user.uid);
    return updateDoc(userDocRef, user);
  }

  static hasSubscription(user: AuthUser): boolean {
    return !["trial", "free"].includes(user.tier);
  }
}
