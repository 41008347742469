export const STYLE_OBJS = [
  {
    id: "inkgeometricalstyle",
    name: "Geometrical Style",
    img_path: "hosting/tattoo_style_samples/geometric.png",
  },
  {
    id: "inkfinelinestyle",
    name: "Fine Line Style",
    img_path: "hosting/tattoo_style_samples/fineline.png",
  },
  {
    id: "inkaestheticstyle",
    name: "Aesthetic Style",
    img_path: "hosting/tattoo_style_samples/aesthetic.png",
  },
  {
    id: "inkdotworkstyle",
    name: "Dot Work Style",
    img_path: "hosting/tattoo_style_samples/dotwork.png",
  },
  {
    id: "inkjapanesestyle",
    name: "Japanese Style",
    img_path: "hosting/tattoo_style_samples/japanese.png",
  },
  {
    id: "inknewschoolstyle",
    name: "New School Style",
    img_path: "hosting/tattoo_style_samples/newschool.png",
  },
  {
    id: "inkonelinestyle",
    name: "One Line Style",
    img_path: "hosting/tattoo_style_samples/oneline.png",
  },
  {
    id: "inkpolygonstyle",
    name: "Polygon Style",
    img_path: "hosting/tattoo_style_samples/polygon.png",
  },
  {
    id: "inkwatercolorstyle",
    name: "Watercolor Style",
    img_path: "hosting/tattoo_style_samples/watercolor.png",
  },
];

export const PORTRAIT_STYLES = STYLE_OBJS.filter((s) =>
  [
    "inkgeometricalstyle",
    "inkfinelinestyle",
    "inkdotworkstyle",
    "inkonelinestyle",
    "inkwatercolorstyle",
  ].includes(s.id),
);

export const PORTRAIT_PROMPTS = [
  "a drawing of a portrait, handsome stunning realistic, tessa thompson inspired, cara delevingne, perfect proportions define face, mostly greyscale, portrait hd, slender symmetrical face, beautiful precise face and body",
  "a drawing of a portrait, trending on deviantarthq, crosshatch, beautiful well rounded face, detailed scan, in style of alan lee, art station",
  "a drawing of a portrait, ig model | artgerm, symmetry portrait, highly detailed sketch, perfect face traditional art, sydney sweeney",
  "a drawing of a portrait, detailed ink drawing, james gurney weta studio, point perspective, highrealism, sketching, scribbled",
];

export const tiers = {
  indie: {
    id: "indie",
    label: "Indie",
    token: 170,
    features: [{ text: "Up to 170 generations", isLive: true }],
  },
  artist: {
    id: "artist",
    label: "Artist",
    token: 550,
    mostPopular: true,
    features: [
      { text: "Up to 550 generations", isLive: true },
      // { text: "<b>30</b> credit-sharing to your clients", isLive: false },
      // { text: "<b>10%</b> off on temp tattoo purchases", isLive: false },
      // { text: "Artist <b>profile page</b>", isLive: false },
      // { text: "<b>API</b> Integration", isLive: false },
      // { text: "<b>Faster</b> Execution", isLive: false },
    ],
  },
  studio: {
    id: "studio",
    label: "Studio",
    token: 1500,
    features: [
      { text: "Up to 1500 generations", isLive: true },
      // { text: "<b>100</b> credit-sharing to your clients", isLive: false },
      // { text: "<b>20%</b> off on temp tattoo purchases", isLive: false },
      // { text: "Studio <b>profile page</b>", isLive: false },
      // { text: "<b>API</b> Integration", isLive: false },
      // { text: "<b>Faster</b> Execution", isLive: false },
      // { text: "Customer booking system", isLive: false },
      // { text: "Payment system", isLive: false },
    ],
  },
};
