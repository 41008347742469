import * as React from "react";
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import createTheme from "./theme/theme";
import { AuthProvider } from "./contexts/auth-context";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

const Fonts = () => (
  <>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400&display=swap"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@600;700&display=swap"
    />
  </>
);

const customTheme = createTheme();

root.render(
  <AuthProvider>
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Fonts />
      <App />
    </ThemeProvider>
  </AuthProvider>,
);
