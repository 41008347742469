import * as React from "react";
import Typography from "@mui/material/Typography";
import UploadIcon from "@mui/icons-material/Upload";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
} from "@mui/material";

export enum GenerationMethod {
  DragAndDrop = "DragAndDrop",
  Webcam = "Webcam",
}

export interface StepOneProps {
  setMethod: (method: GenerationMethod) => void;
}

export default function MethodSelection({ setMethod }: StepOneProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        borderRadius: "8px",
      }}
    >
      <Card sx={{ width: "100%", mb: { xs: 2, md: 0 }, mr: { xs: 0, md: 2 } }}>
        <CardActionArea onClick={() => setMethod(GenerationMethod.DragAndDrop)}>
          <UploadIcon
            sx={{
              width: 100,
              height: 60,
              color: "#6366F1",
            }}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Upload a Photo
            </Typography>
            <Typography variant="body2" color="text.secondary">
              For the best result, use a photo where your face is centrally positioned, occupies a
              significant portion of the frame, and is not obstructed by any objects or accessories.
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => setMethod(GenerationMethod.DragAndDrop)}
          >
            <Typography variant="subtitle2">Upload</Typography>
          </Button>
        </CardActions>
      </Card>
      <Divider component="div" orientation="vertical" flexItem sx={{ height: "auto", mr: 2 }} />
      <Card sx={{ width: "100%", mb: { xs: 2, md: 0 }, mr: { xs: 0, md: 2 } }}>
        <CardActionArea onClick={() => setMethod(GenerationMethod.Webcam)}>
          <PhotoCameraIcon sx={{ width: 100, height: 60, color: "#6366F1" }} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Take a Photo
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Please ensure your face is centered within the frame and fills a considerable part of
              the image. Avoid any obstructions that may cover your face.
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" onClick={() => setMethod(GenerationMethod.Webcam)}>
            <Typography variant="subtitle2">Take</Typography>
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
