import React from "react";
import { Box, Modal, Skeleton, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ImageModalProps } from "../../../functions/src/types";

// Modal style
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
  outline: "none",
  border: "none",
  "&:focus": {
    outline: "none",
  },
  "&:hover": {
    outline: "none",
  },
  "&:active": {
    outline: "none",
  },
  "&:visited": {
    outline: "none",
  },
  "&:focus-visible": {
    outline: "none",
  },
  "&:focus-within": {
    outline: "none",
  },
};

const ImageModal = ({ handleClose, imgUrl, open, refined_prompt }: ImageModalProps) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton onClick={handleClose} style={{ position: "absolute", top: 5, right: 5 }}>
          <Close />
        </IconButton>
        {imgUrl ? (
          <img
            src={imgUrl}
            alt={refined_prompt}
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
              borderRadius: "8px",
            }}
          />
        ) : (
          <Skeleton variant="rectangular" width={400} height={300} />
        )}
      </Box>
    </Modal>
  );
};

export default ImageModal;
