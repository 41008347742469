import { Box, Divider, MenuItem, MenuList, Popover, Typography } from "@mui/material";
import React from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../hooks/use-auth";
import { Timestamp } from "@firebase/firestore-types";

// Format as YYYY-MM-DD
const formatDate = (timestamp: Timestamp) => {
  const date = timestamp.toDate();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const AcountNavbar = ({ anchorEl, onClose, open }) => {
  //TODO get users data an populate with data from BE
  //TODO in handleSignout we need to get the status which will be a dependency [onClose, navigate, auth]

  const auth = useAuth();

  const navigate = useNavigate();
  const handleSignOut = useCallback(() => {
    onClose?.();
    auth.signOut().then(() => {
      navigate("/");
    });
  }, [auth, onClose, navigate]);
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      slotProps={{ paper: { sx: { width: 200 } } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <Typography variant="overline">Account</Typography>
        <Typography color="text.secondary" variant="body2">
          {auth.user?.username}
        </Typography>
        <Divider />
        <Typography variant="overline">Tier</Typography>
        <Typography color="text.secondary" variant="body2">
          {auth.user?.tier}
          {auth.user?.subscription_interval ? ` - ${auth.user?.subscription_interval}ly` : ""}
        </Typography>
        <Divider />
        <Typography variant="overline">Credit</Typography>
        <Typography color="text.secondary" variant="body2">
          {auth.user?.user_credit}
        </Typography>
        {auth.user?.subscription_expires_at && (
          <>
            <Divider />
            <Typography variant="overline">Expires at</Typography>
            <Typography color="text.secondary" variant="body2">
              {formatDate(auth.user.subscription_expires_at)}
            </Typography>
          </>
        )}
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </MenuList>
    </Popover>
  );
};

export default AcountNavbar;
