import { Box, Divider, Drawer, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import NavItems from "./nav-items";
import RocketLaunchIcon from "@heroicons/react/24/solid/RocketLaunchIcon";

const SideNav = ({ open, onClose }) => {
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));
  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "270px",
        maxWidth: "279px",
        background: "rgb(28, 37, 54)",
        position: "fixed",
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.04)",
            borderRadius: 1,
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            p: "12px",
          }}
        >
          <Typography color="#9DA4AE" variant="subtitle1">
            Inkcredible
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        component="nav"
        sx={{
          flexGrow: 1,
          px: 2,
          py: 3,
        }}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: "none",
            p: 0,
            m: 0,
          }}
        >
          <NavItems />
        </Stack>
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        sx={{
          px: 4,
          py: 3,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography color="#9DA4AE" variant="subtitle2" sx={{ cursor: "pointer" }}>
          Upgrade to Pro
        </Typography>
        <Box
          sx={{
            width: "16px",
            height: "16px",
            ml: 1,
            color: "#9DA4AE",
            mb: 0.6,
          }}
        >
          <RocketLaunchIcon />
        </Box>
      </Box>
    </Box>
  );
  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.800",
            color: "common.white",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.800",
          color: "common.white",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default SideNav;
