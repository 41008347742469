import React, { useCallback, useRef, useState } from "react";
import { Box, Paper, CircularProgress, Button } from "@mui/material";
import Webcam from "react-webcam";

interface WebcamProps {
  onFileUpload: (image: string) => void;
}

const WebcamBox: React.FC<WebcamProps> = ({ onFileUpload }) => {
  const [loadCamera, setLoadCamera] = useState<boolean>(true);
  const webcamRef = useRef<Webcam>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const capture = useCallback(() => {
    setLoading(true);
    const imgageSrc = webcamRef.current?.getScreenshot();
    if (imgageSrc) {
      setImagePreview(imgageSrc);
      setLoadCamera(false);
      setLoading(false);
      onFileUpload(imgageSrc);
    }
  }, [webcamRef, setImagePreview, setLoadCamera, onFileUpload]);

  return (
    <Box>
      <Paper
        variant="outlined"
        style={{
          border: "2px dashed #aaa",
          padding: "10px",
          textAlign: "center",
          height: "100%",
          cursor: "pointer",
          background: "#fafafa",
          position: "relative",
        }}
      >
        {imagePreview && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            sx={{ width: "100%" }}
          >
            <img src={imagePreview} alt="your tattoo" style={{ maxWidth: "50%", height: "auto" }} />
          </Box>
        )}
        <label>
          <Box display="flex" flexDirection="column" alignItems="center">
            {loadCamera && (
              <>
                <Webcam ref={webcamRef} style={{ maxWidth: "100%" }} />
                <Button onClick={capture}>Take a picture</Button>
              </>
            )}
          </Box>
        </label>
        {loading && (
          <CircularProgress
            size={24}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Paper>
    </Box>
  );
};

export default WebcamBox;
